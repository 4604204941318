import { translate } from '@/core/localization/translate';
export const {
  footerLegalCompanyInformation,
  footerLegalCompanyAddress,
  footerLegalCompanyDetailsDescription,
  footerLegalCompanyDetailsTitle,
  footerLegalConsumptionInformation,
  footerLegalConsumptionInformationB2B,
  footerLegalConsumptionInformationHref,
  footerLegalConsumptionInformationHrefB2B,
  footerLegalManageCookies,
  footerLegalMentions,
  footerLegalMentionsHref,
  footerLegalMentionsHrefB2B,
  footerLegalProtection,
  footerLegalProtectionHref,
  footerLegalReports,
  footerLegalReportsHref,
  footerLegalTerms,
  footerLegalTermsHref,
  footerLegalTermsHrefB2B,
  footerLegalTrustCenter,
  footerLegalTrustCenterHref,
  footerSitemap,
  footerSitemapHref
} = translate({
  "footerLegalCompanyInformation": "Informazioni Societarie",
  "footerLegalCompanyAddress": "52 rue Bayen, 75017 Paris, Francia",
  "footerLegalCompanyDetailsDescription": "Iscritto al RCS di Nanterra con il n\xB0 752 979 930",
  "footerLegalCompanyDetailsTitle": "Colibri SAS, con un capital di 88.370,88\u20AC",
  "footerLegalConsumptionInformation": "Informazioni consumatori",
  "footerLegalConsumptionInformationB2B": "Bewertungen auf der Plattform",
  "footerLegalConsumptionInformationHref": "https://cdn.manomano.com/legal/consumer-information/it.pdf",
  "footerLegalConsumptionInformationHrefB2B": "https://cdn.manomano.com/legal/consumer-information/it.pdf",
  "footerLegalManageCookies": "Imposta i miei cookies",
  "footerLegalMentions": "Menzioni legali",
  "footerLegalMentionsHref": "https://cdn.manomano.com/legal/legal-notice/it.pdf",
  "footerLegalMentionsHrefB2B": "https://cdn.manomano.com/legal/legal-notice/it.pdf",
  "footerLegalProtection": " Politica per la protezione dei data personali",
  "footerLegalProtectionHref": "https://cdn.manomano.com/legal/data-protection/it.pdf",
  "footerLegalTrustCenter": "Trust Center",
  "footerLegalTrustCenterHref": "https://trust.manomano.com/",
  "footerLegalReports": "Rapporto",
  "footerLegalReportsHref": "/lp/rapporto-di-trasparenza-3228",
  "footerLegalTerms": " Condizioni generali d'uso",
  "footerLegalTermsHref": "https://cdn.manomano.com/legal/terms-of-service/it.pdf",
  "footerLegalTermsHrefB2B": "https://cdn.manomano.com/legal/terms-of-service/it.pdf",
  "footerSitemap": "Mappa del sito",
  "footerSitemapHref": "/mappa-del-sito"
});