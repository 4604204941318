import dynamic from 'next/dynamic';

import { isB2C } from '@/core/settings/utils';
import { useAuth } from '@/customerManagement/auth/hooks/useAuth';
import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { ABTests } from '@/domains/proB2BServices/AddToWishlist/abTests';
import { Promos } from '@/productDiscovery/HeaderLeonidas/b2b/components/Promos/Promos';
import { Cart } from '@/productDiscovery/HeaderLeonidas/components/Cart/Cart';
import { HeaderBanner } from '@/productDiscovery/HeaderLeonidas/components/HeaderBanner/HeaderBanner';
import { Menu } from '@/productDiscovery/HeaderLeonidas/components/Menu/Menu';
import { MenuButton } from '@/productDiscovery/HeaderLeonidas/components/MenuButton/MenuButton';
import { QuickAccessLinks } from '@/productDiscovery/HeaderLeonidas/components/QuickAccessLinks/QuickAccessLinks';
import { useMatchMedia } from '@/productDiscovery/HeaderLeonidas/hooks/useMatchMedia';
import { Search } from '@/productDiscovery/HeaderLeonidas/modules/searchBar/components/Search/Search';

import { Logo } from '../Logo/Logo';
import { HeaderMainRoot } from './components/HeaderMainRoot';

import styles from './HeaderMain.module.scss';

const B2BAccount = dynamic(
  async () =>
    (
      await import(
        '@/productDiscovery/HeaderLeonidas/b2b/components/Account/Account'
      )
    ).Account,
  { ssr: true },
);
const B2CAccount = dynamic(
  async () =>
    (
      await import(
        '@/productDiscovery/HeaderLeonidas/b2c/components/Account/Account'
      )
    ).Account,
  { ssr: true },
);
const AdvisorContact = dynamic(
  async () =>
    (
      await import(
        '@/productDiscovery/HeaderLeonidas/components/AdvisorContact/AdvisorContact'
      )
    ).AdvisorContact,
  { ssr: true },
);
const ProButton = dynamic(
  async () =>
    (
      await import(
        '@/productDiscovery/HeaderLeonidas/b2c/components/ProButton/ProButton'
      )
    ).ProButton,
  { ssr: true },
);
const WalletButtonContainer = dynamic(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/HeaderLeonidas/components/WalletButton/WalletButtonContainer'
      )
    ).WalletButtonContainer,
  { ssr: true },
);
const WishListIcon = dynamic(
  async () =>
    (
      await import(
        '@/productDiscovery/HeaderLeonidas/components/WishListIcon/WishListIcon'
      )
    ).WishListIcon,
  { ssr: true },
);
const WishListIconWithMiniPopup = dynamic(
  async () =>
    (
      await import(
        '@/productDiscovery/HeaderLeonidas/components/WishListIconWithMiniPopup/WishListIconWithMiniPopup'
      )
    ).WishListIconWithMiniPopup,
  { ssr: true },
);
export const HeaderMain = () => {
  const { isAuthenticated } = useAuth();
  const isLargeViewport = useMatchMedia('(min-width: 1024px)');
  const [isWishlistRevampEnabled] = useBooleanFlags([
    ABTests.WISHLIST_REVAMP_ENABLED,
  ]);

  const wishlistIconComp = isWishlistRevampEnabled ? (
    <WishListIconWithMiniPopup />
  ) : (
    <WishListIcon />
  );

  return (
    <HeaderMainRoot className={styles.mainBar}>
      <div className={styles.mainBarContent}>
        <MenuButton className={styles.menuButton} />
        <Menu />
        <Logo className={styles.logo} />
        <div className={styles.navigation}>
          {!isB2C() && <AdvisorContact />}
          {isB2C() && <ProButton className={styles.proButton} />}
          <WalletButtonContainer />
          {isB2C() ? (
            <B2CAccount className={styles.account} />
          ) : (
            <B2BAccount className={styles.account} />
          )}
          {(isAuthenticated || !isB2C()) && wishlistIconComp}
          <Cart />
        </div>
        <Search className={styles.search} />
        {!isB2C() && <Promos />}
        <QuickAccessLinks className={styles.navbar} />
        <div className={styles.headerBanner}>
          {isLargeViewport && <HeaderBanner />}
        </div>
      </div>
    </HeaderMainRoot>
  );
};

HeaderMain.displayName = 'HeaderMain';
