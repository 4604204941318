import { translate } from '@/domains/core/localization/translate';
export const {
  rootLabel
} = translate({
  "rootLabel": "Suggerimenti di ricerca"
});
export const {
  autocomplete,
  emptySearchB2C,
  emptySearchB2B,
  autocompleteB2B,
  searchHistory,
  deleteHistory,
  searchAI
} = {
  autocomplete: translate({
    "searchBrandTitle": "Top marche"
  }),
  emptySearchB2C: translate({
    "searchBrandTitle": "Marche del momento",
    "searchCategoryTitle": "Categorie del momento",
    "searchQueryTitle": "Ricerche pi\xF9 comuni"
  }),
  emptySearchB2B: translate({
    "searchBrandTitle": "Marche Pro",
    "searchCategoryTitle": "Categorie del momento",
    "searchQueryTitle": "Le ricerche dei Pro"
  }),
  autocompleteB2B: translate({
    "searchProSelection": "Le migliori selezioni Pro"
  }),
  searchHistory: translate({
    "title": "Le tue ricerche recenti"
  }),
  deleteHistory: translate({
    "title": "Rimuovi tutto"
  }),
  searchAI: translate({
    "title": "Exemples de recherches",
    "history": "Recherches r\xE9centes"
  })
};