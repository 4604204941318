import { translate } from '@/domains/core/localization/translate';
export const {
  defaultTopProductsTitle,
  // = mobile
  topProductsB2BTitle,
  topSalesTitle,
  lastSeenTitle,
  similarityTitle,
  favoriteProductsB2BTitle
} = translate({
  "defaultTopProductsTitle": "Migliori vendite",
  "topProductsB2BTitle": "I migliori prodotti Pro",
  "topSalesTitle": "I prodotti pi\xF9 venduti del momento",
  "lastSeenTitle": "Prodotti visti di recente",
  "similarityTitle": "Questi articoli potrebbero interessarti !",
  "favoriteProductsB2BTitle": "Prodotti consigliati"
});