import { translate } from '@/domains/core/localization/translate';
export const {
  leonidasMenuExploreCatalogue,
  leonidasMenuLinksTitle,
  leonidasMenuProductsTitle,
  leonidasMenuSeeMoreTitle
} = translate({
  "leonidasMenuExploreCatalogue": "Esplora il catalogo",
  "leonidasMenuLinksTitle": "Le nostre competenze",
  "leonidasMenuProductsTitle": "Prodotti",
  "leonidasMenuSeeMoreTitle": "Vedi altro"
});