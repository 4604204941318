import { translate, translateFormatter } from '@/core/localization/translate';
export const countdownDaysLeft = translateFormatter<{
  days: number;
}>("{days, plural, =1 {# giorno rimanente} other {# giorni rimanenti}}", undefined, undefined, "it");
export const countdownTimeLeft = translateFormatter<{
  hoursFormatted: string;
  minutesFormatted: string;
  secondsFormatted: string;
}>("{hoursFormatted}o {minutesFormatted}m {secondsFormatted}s", undefined, undefined, "it");
export const {
  countdownDays,
  countdownHours,
  countdownMinutes,
  countdownSeconds
} = translate({
  "countdownDays": "GIORNI",
  "countdownHours": "ORE",
  "countdownMinutes": "MIN",
  "countdownSeconds": "SEC"
});