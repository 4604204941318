import { translate } from '@/domains/core/localization/translate';
export const {
  headerSearchB2C,
  headerSearchB2B,
  headerSearchCommon,
  headerSearchAI
} = {
  headerSearchB2C: translate({
    "label": "Fai una ricerca su ManoMano",
    "placeholder": "Cerca un prodotto o una marca",
    "placeholderMobile": "Cerca su ManoMano"
  }),
  headerSearchB2B: translate({
    "label": "Cerca prodotti e marchi",
    "placeholder": "Cerca un prodotto o una marca",
    "placeholderMobile": "Cerca su ManoMano"
  }),
  headerSearchCommon: translate({
    "delete": "Pulire",
    "magnifyingGlass": "Cerca"
  }),
  headerSearchAI: translate({
    "placeholder": "Que recherchez-vous ?"
  })
};